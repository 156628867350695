import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { ChannelAPI } from "../../../utils/api/requests/channel-requests";

interface DeleteChannelModalProps {
  open: boolean;
  handleClose: () => void;
  id: number;
  refetch: () => void;
}

export const DeleteChannelModal: FC<DeleteChannelModalProps> = ({
  open,
  handleClose,
  id,
  refetch,
}) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: ChannelAPI.deleteChannel,
    onSuccess: () => {
      toast.success("Канал успешно удалён");
      refetch();
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const handlePostDelete = async () => {
    mutate(id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Удаление канала</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ minWidth: "350px", minHeight: "20px" }}
        >
          Вы действительно хотите удалить канал?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "10px" }}>
        <Button variant="outlined" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          onClick={handlePostDelete}
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
