import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Folder } from "../../../types";
import { DeleteFolderModal } from "../../organisms/DeleteFolderModal";
import EditIcon from "@mui/icons-material/Edit";
import { UpdateFolderModal } from "../../organisms/UpdateFolderModal";
// import { useNavigate } from "react-router-dom";

interface FolderItemProps {
  folder: Folder;
  refetch: () => void;
  setFolder: (folder: Folder) => void;
}

export const FolderItem: FC<FolderItemProps> = ({
  folder,
  refetch,
  setFolder,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  //   const navigate = useNavigate();

  const handleCloseModal = () => {
    setModal(false);
  };

  const onRowClick = () => {
    setFolder(folder);
    // navigate(`/channels/${channel.id}`);
  };

  return (
    <>
      <Box
        onClick={onRowClick}
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns: "1fr 0.1fr",
          px: "8px",
          cursor: "pointer",
          paddingBottom: "10px",
          "&:hover": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FolderIcon style={{ marginRight: "4px" }} />
          {folder.name}
          {folder.id !== 0 && (
            <EditIcon
              fontSize="small"
              style={{ transition: "all 0.2s linear" }}
              sx={{
                ml: "6px",
                cursor: "pointer",
                "&:hover": { color: "#1976d2" },
              }}
              color="disabled"
              onClick={(e) => {
                e.stopPropagation();
                setEditModal(true);
              }}
            />
          )}
        </Box>
        {folder.id !== 0 && (
          <Box
            sx={{
              color: "#616161",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              cursor: "pointer",
              mr: "12px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setModal(true);
            }}
          >
            <DeleteOutlineIcon sx={{ mr: "4px" }} />
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              Удалить
            </Typography>
          </Box>
        )}
      </Box>
      <DeleteFolderModal
        open={modal}
        handleClose={handleCloseModal}
        id={folder.id}
        refetch={refetch}
      />
      <UpdateFolderModal
        open={editModal}
        handleClose={() => setEditModal(false)}
        refetch={refetch}
        folder={folder}
      />
    </>
  );
};
