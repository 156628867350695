import { Container } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { NavBar } from "../../ui/organisms/NavBar";
import { Accounts } from "../../accounts";
import { ToastContainer } from "react-toastify";
import { Channels } from "../../channels";
import { Details } from "../../channels/Details";
import { Orders } from "../../orders";
import Logs from "../../channels/Logs";

export const MainTemplate = () => (
  <>
    <NavBar />
    <Container maxWidth="lg" sx={{ mt: "12px" }}>
      <Routes>
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/channels" element={<Channels />} />
        <Route path="/channels/:id" element={<Details />} />
        <Route path="/channels/:id/logs" element={<Logs />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="*" element={<Navigate to="/accounts" replace />} />
      </Routes>
    </Container>
    <ToastContainer
      position="top-center"
      autoClose={2500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
      style={{ padding: "10px" }}
    />
  </>
);
