import Joi from "joi";
import { Order, OrderPayload } from "../../types";

export const getOrderSchema = () =>
  Joi.object<any>({
    orders: Joi.array<any>().items(
      Joi.object({
        value: Joi.number().required().min(1).allow(""),
        interval: Joi.number().required().min(1),
        anti_minus: Joi.number().required().min(1).allow(""),
        type: Joi.string().required(),
        date: Joi.date().required(),
        orders: Joi.array().items(Joi.number().required()).min(1),
      }).unknown(true)
    ),
  });

export const getOrderCreateSchema = () =>
  Joi.object<OrderPayload>({
    name: Joi.string().required(),
    type: Joi.string().required(),
    external_id: Joi.string().required(),
  }).unknown(true);

export const getOrderUpdateSchema = () =>
  Joi.object<Order>({
    name: Joi.string().required(),
    external_id: Joi.string().required(),
  }).unknown(true);
