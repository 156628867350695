import { Box, Link, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ChannelAPI } from "../../../utils/api/requests/channel-requests";
import { useMutation, useQuery } from "react-query";
import { OrderData } from "../../../types";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { getOrderSchema } from "../../../utils/schemas/OrderSchemas";
import { OrderItem } from "./OrderItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { v4 } from "uuid";

import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { useNavigate, useParams } from "react-router-dom";
import { OrderTypes } from "../../../utils/consts";

interface OrderSectionProps {
  ordersArray: OrderData[];
  refetch: () => void;
}

export const OrderSection: FC<OrderSectionProps> = ({
  ordersArray,
  refetch,
}) => {
  const { id } = useParams<Record<"id", string>>();
  const navigate = useNavigate();
  const [isEditing, setEditing] = useState(false);
  const { data } = useQuery({
    queryFn: () => ChannelAPI.getOrders(),
    queryKey: "orders",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const subscribers =
    data
      ?.filter((item) => {
        return item.type === "subscribers";
      })
      .map((item) => {
        return { label: item.name, value: item.id };
      }) || [];

  const form = useForm<{
    orders: {
      value: number | string;
      type: string;
      orders: number[];
      date: Date;
      _id: string;
      interval: number;
      anti_minus: number | string;
    }[];
  }>({
    mode: "onChange",
    resolver: joiResolver(getOrderSchema()),
  });

  const { watch, reset, setValue, handleSubmit } = form;

  useEffect(() => {
    const arr = ordersArray.map((order) => {
      return {
        _id: v4(),
        value: order.value || "",
        type: order.type,
        orders: order.orders,
        date: new Date(order.date),
        interval: order.interval,
        anti_minus: order.anti_minus || "",
      };
    });
    reset({ orders: arr });
  }, [ordersArray, reset]);

  const orders = watch("orders");

  const addOrder = () => {
    const emptyOrder = {
      _id: v4(),
      value: "",
      type: "",
      orders: [],
      date: new Date(),
      interval: 0,
      anti_minus: "",
    };
    orders
      ? setValue("orders", [...orders, emptyOrder])
      : setValue("orders", [emptyOrder]);
  };

  const removeOrder = (_id: string) => {
    const index = orders.findIndex((item) => item._id === _id);
    if (index !== -1) {
      const newOrders = [...orders];
      newOrders.splice(index, 1);
      setValue("orders", newOrders);
    }
  };

  const { mutate } = useMutation({
    mutationFn: ChannelAPI.updateOrders,
    onSuccess: () => {
      refetch();
      toast.success("Заказы успешно изменены");
      setEditing(false);
    },
    onError: (error) => {
      const err = error as AxiosError;
      const errorText = err?.response?.data?.detail;
      if (typeof errorText === "string") {
        toast.error(err?.response?.data?.detail);
      } else {
        toast.error("Ошибка");
      }
    },
  });

  const onSubmit = (data: {
    orders: {
      value: number | null | string;
      type: string;
      orders: number[];
      date: Date | string;
      _id?: string;
      interval: number;
      anti_minus: number | null | string;
    }[];
  }) => {
    data.orders.forEach((item) => {
      if (item.hasOwnProperty("_id")) {
        delete item._id;
      }
      if (item.hasOwnProperty("value") && item.value === "") {
        item.value = null;
      }
      if (item.hasOwnProperty("anti_minus") && item.anti_minus === "") {
        item.anti_minus = null;
      }
      item.date = (item.date as Date).toISOString();
    });
    //@ts-ignore
    mutate({ payload: data.orders, id: id! });
  };

  const onCancel = () => {
    const arr = ordersArray.map((order) => {
      return {
        _id: v4(),
        value: order.value || "",
        type: order.type,
        orders: order.orders,
        date: new Date(order.date),
        interval: order.interval,
        anti_minus: order.anti_minus || "",
      };
    });
    reset({ orders: arr });
  };

  return (
    <FormProvider {...form}>
      <Box component="form">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Typography
            sx={{
              ml: "40px",
              mt: "32px",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Заказы
            {isEditing ? (
              <>
                <AddCircleIcon
                  fontSize="medium"
                  style={{ transition: "all 0.2s linear" }}
                  sx={{
                    ml: "6px",
                    mt: "4px",
                    cursor: "pointer",
                    "&:hover": { color: "#1976d2" },
                  }}
                  color="disabled"
                  onClick={addOrder}
                />
                <SaveIcon
                  fontSize="medium"
                  style={{ transition: "all 0.2s linear" }}
                  sx={{
                    ml: "6px",
                    mt: "4px",
                    cursor: "pointer",
                    "&:hover": { color: "#1976d2" },
                  }}
                  onClick={() => {
                    handleSubmit(onSubmit)();
                  }}
                  color="disabled"
                />
                <DoDisturbIcon
                  fontSize="medium"
                  style={{ transition: "all 0.2s linear" }}
                  sx={{
                    ml: "6px",
                    mt: "4px",
                    cursor: "pointer",
                    "&:hover": { color: "#1976d2" },
                  }}
                  color="disabled"
                  onClick={() => {
                    onCancel();
                    setEditing(false);
                  }}
                />
              </>
            ) : (
              <EditIcon
                fontSize="medium"
                style={{ transition: "all 0.2s linear" }}
                sx={{
                  ml: "6px",
                  mt: "4px",
                  cursor: "pointer",
                  "&:hover": { color: "#1976d2" },
                }}
                color="disabled"
                onClick={() => {
                  setEditing(true);
                }}
              />
            )}
          </Typography>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/channels/${id!}/logs`);
            }}
          >
            Логи
          </Link>
        </Box>

        {orders?.map((item, idx) => (
          <OrderItem
            types={OrderTypes}
            subscribers={subscribers}
            idx={idx}
            key={item._id}
            _id={item._id}
            removeOrder={removeOrder}
            isEditing={isEditing}
          />
        ))}
        {(!orders || orders.length === 0) && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: "100px" }}>
            Заказов нет
          </Box>
        )}
      </Box>
    </FormProvider>
  );
};
