import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { FolderPayload } from "../../../types";
import { ChannelAPI } from "../../../utils/api/requests/channel-requests";
import { getFolderSchema } from "../../../utils/schemas/ChannelSchemas";

interface AddFolderModalProps {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
}

export const AddFolderModal: FC<AddFolderModalProps> = ({
  open,
  handleClose,
  refetch,
}) => {
  const form = useForm<FolderPayload>({
    mode: "onChange",
    resolver: joiResolver(getFolderSchema()),
  });
  const { handleSubmit, formState, control, reset } = form;

  const { isDirty, isValid } = formState;

  const { mutate, isLoading } = useMutation({
    mutationFn: ChannelAPI.addFolder,
    onSuccess: () => {
      refetch();
      toast.success("Папка успешно добавлена");
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const onSubmit = async (formData: FolderPayload) => {
    mutate(formData.name);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Добавить папку</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", width: "350px" }}
          >
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={"Название папки"}
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    marginTop: "12px",
                  }}
                  error={!!error}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
            >
              Добавить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
