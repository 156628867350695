import { Box, CircularProgress, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery } from "react-query";
import { useState } from "react";
import { OrderAPI } from "../utils/api/requests/order-requests";
import { OrderItem } from "../ui/molecules/OrderItem";
import { AddOrderModal } from "../ui/organisms/AddOrderModal";

export const Orders = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const { data, refetch } = useQuery({
    queryFn: OrderAPI.getOrdersList,
    queryKey: "orders",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Box sx={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <Typography variant="h4">Услуги</Typography>
        <AddCircleIcon
          fontSize="medium"
          style={{ transition: "all 0.2s linear" }}
          sx={{
            ml: "6px",
            mt: "4px",
            cursor: "pointer",
            "&:hover": { color: "#1976d2" },
          }}
          color="disabled"
          onClick={() => {
            setModalOpened(true);
          }}
        />
        {/* Обновить */}
      </Box>
      {!data && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {data &&
        data.length !== 0 &&
        data.map((order) => {
          return <OrderItem refetch={refetch} order={order} key={order.id} />;
        })}
      {data && data.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Каналов нет</div>
        </Box>
      )}
      <AddOrderModal
        open={modalOpened}
        handleClose={() => {
          setModalOpened(false);
        }}
        refetch={refetch}
      />
    </>
  );
};
