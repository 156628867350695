import { Status } from "../types";
import { green } from "@mui/material/colors";

export const getColor = (status: Status) => {
  if (status === "active") {
    return green[500];
  }
  if (status === "blocked") {
    return "error";
  }
};
