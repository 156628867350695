import React, { useEffect, useState } from "react";
import { ChannelAPI } from "../utils/api/requests/channel-requests";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { LogItem } from "../ui/molecules/LogItem";
import { getFormattedNumber } from "../utils/GetFormattedNumber";
import { QueryDatePicker } from "../ui/atoms/QueryDatePicker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { queryClient } from "../..";

const limit = 25;
const scrollIdent = 300;

export default function Logs() {
  const { id } = useParams<Record<"id", string>>();
  const navigate = useNavigate();
  const [scrollLimit, setScrollLimit] = useState(limit);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const { data, refetch } = useQuery({
    queryFn: () =>
      ChannelAPI.getChannelLogs({
        id: id!,
        fromDate: fromDate,
        toDate: toDate,
        limit: scrollLimit,
      }),
    onSuccess: () => {
      setLoading(false);
    },
    queryKey: "logs",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setScrollLimit(limit);
    refetch();
  }, [toDate, fromDate, refetch]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries("logs");
      queryClient.resetQueries("channelData");
    };
  }, []);

  useEffect(() => {
    if (scrollLimit !== limit) {
      if (data && data?.count > data?.logs.length) {
        setLoading(true);
        refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, scrollLimit]);

  useEffect(() => {
    setScrollLimit(limit);
    refetch();
  }, [refetch]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    if (windowHeight + scrollTop + scrollIdent >= documentHeight && !loading) {
      setScrollLimit(scrollLimit + limit);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { data: channel } = useQuery({
    queryFn: () => ChannelAPI.getChannel(id!),
    onError: () => {
      navigate("/channels");
    },
    queryKey: "channelData",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {channel && data && (
        <Box
          sx={{
            marginBottom: "12px",
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              fontSize="medium"
              style={{ transition: "all 0.2s linear" }}
              sx={{
                ml: "6px",
                mt: "4px",
                cursor: "pointer",
                "&:hover": { color: "#1976d2" },
              }}
              color="disabled"
              onClick={() => {
                navigate(`/channels/${id}`);
              }}
            />
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="h5"
                sx={{
                  maxWidth: "300px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >{`Логи ${channel?.channel.name}`}</Typography>{" "}
              <Typography
                variant="h5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                (<CheckCircleIcon color="success" />{" "}
                {getFormattedNumber(data.completed)} |{" "}
                <CancelIcon color="error" fontSize="medium" />{" "}
                {getFormattedNumber(data.failed)} )
              </Typography>
            </Box>
          </Box>
          {/* <Typography variant="h5">
              {`Логи ${channel?.channel.name}`}(Выполненно:
              {getFormattedNumber(data.completed)} | Неудачные:
              {getFormattedNumber(data.failed)} )
            </Typography> */}
          {/* (Всего: ${getFormattedNumber(data.completed)}) */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <QueryDatePicker
              value={fromDate}
              setValue={setFromDate}
              placeholder="Начало периода"
            />
            <Typography sx={{ ml: "6px", mr: "6px" }}> —</Typography>
            <QueryDatePicker
              value={toDate}
              setValue={setToDate}
              placeholder="Конец периода"
            />
          </Box>
        </Box>
      )}

      {!channel && !data && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {data &&
        channel &&
        data.logs.length !== 0 &&
        data.logs.map((log, idx) => {
          return <LogItem log={log} key={idx} />;
        })}
      {data && data.logs.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Логов нет</div>
        </Box>
      )}
    </>
  );
}
