import { Box, InputLabel, TextField } from "@mui/material";
import React, { FC } from "react";
import { MultiSelect } from "../../atoms/MultiSelect";
import { DatePickerComponent } from "../../atoms/DatePicker";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getValue, needMultiply } from "../../../utils/inputTransform";
import { Select } from "../../atoms/Select";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { isValueBetween } from "../../../utils/isValueBetween";

interface OrderItemProps {
  types: { value: any; label: string }[];
  subscribers: { value: any; label: string }[];
  idx: number;
  _id: string;
  removeOrder: (_id: string) => void;
  isEditing: boolean;
}

export const OrderItem: FC<OrderItemProps> = ({
  types,
  subscribers,
  idx,
  removeOrder,
  _id,
  isEditing,
}) => {
  const context = useFormContext();
  if (!context) {
    throw new Error("Provide FormContext before ImageUpload");
  }
  const { control } = context;

  return (
    <Box sx={{ display: "flex", my: "10px", ml: "40px", alignItems: "center" }}>
      <Select data={types} name={`orders[${idx}].type`} isEditing={isEditing} />
      <MultiSelect
        data={subscribers}
        name={`orders[${idx}].orders`}
        isEditing={isEditing}
      />
      <DatePickerComponent name={`orders[${idx}].date`} isEditing={isEditing} />
      <Box sx={{ ml: "10px" }}>
        <InputLabel>Количество</InputLabel>
        <Controller
          control={control}
          defaultValue={""}
          name={`orders[${idx}].value`}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState: { error },
          }) =>
            isEditing ? (
              <NumberFormat
                customInput={TextField}
                inputRef={ref}
                onValueChange={needMultiply(1, onChange)}
                value={getValue(value, true, 1)}
                type="text"
                variant="outlined"
                error={!!error}
                fullWidth={true}
                decimalScale={0}
                allowNegative={false}
              />
            ) : (
              <Box
                sx={{
                  width: "107px",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value || "-"}
              </Box>
            )
          }
        />
      </Box>
      <Box sx={{ ml: "10px" }}>
        <InputLabel>Антиминус,%</InputLabel>
        <Controller
          control={control}
          defaultValue={""}
          name={`orders[${idx}].anti_minus`}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState: { error },
          }) =>
            isEditing ? (
              <NumberFormat
                customInput={TextField}
                inputRef={ref}
                onValueChange={needMultiply(1, onChange)}
                value={getValue(value, true, 1)}
                type="text"
                variant="outlined"
                error={!!error}
                fullWidth={true}
                decimalScale={0}
                isAllowed={({ floatValue }) =>
                  floatValue === undefined || isValueBetween(floatValue, 0, 100)
                }
                allowNegative={false}
                min={1}
              />
            ) : (
              <Box
                sx={{
                  width: "107px",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value || "-"}
              </Box>
            )
          }
        />
      </Box>
      <Box sx={{ ml: "10px" }}>
        <InputLabel>Интервал</InputLabel>

        <Controller
          control={control}
          defaultValue={0}
          name={`orders[${idx}].interval`}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState: { error },
          }) =>
            isEditing ? (
              <NumberFormat
                customInput={TextField}
                inputRef={ref}
                onValueChange={needMultiply(1, onChange)}
                value={getValue(value, true, 1)}
                type="text"
                variant="outlined"
                error={!!error}
                fullWidth={true}
                decimalScale={0}
                allowNegative={false}
              />
            ) : (
              <Box
                sx={{
                  width: "107px",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </Box>
            )
          }
        />
      </Box>
      {isEditing && (
        <DeleteOutlineIcon
          style={{ transition: "all 0.2s linear" }}
          sx={{
            ml: "14px",
            mt: "20px",
            cursor: "pointer",
            "&:hover": { color: "#1976d2" },
          }}
          color="disabled"
          onClick={() => removeOrder(_id)}
        />
      )}
    </Box>
  );
};
