import {
  Box,
  InputLabel,
  MenuItem,
  Select as SelectCompent,
} from "@mui/material";
import { FC } from "react";
import { useController } from "react-hook-form";

interface SelectProps {
  data?: { value: any; label: string }[];
  name: string;
  isEditing: boolean;
  hideLabel?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
}

export const Select: FC<SelectProps> = ({
  data,
  name,
  isEditing,
  hideLabel = false,
  fullWidth = false,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
  });

  const label = data?.find((item) => item.value === value)?.label;

  return (
    <Box>
      {!hideLabel && <InputLabel>Тип услуги</InputLabel>}
      {isEditing ? (
        <SelectCompent
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width: fullWidth ? "100%" : "160px" }}
          onChange={onChange}
          value={value}
          defaultValue={value?.toString()}
          error={!!error}
        >
          {data &&
            data.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </SelectCompent>
      ) : (
        <Box
          sx={{
            width: "160px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>
      )}
    </Box>
  );
};
