export const getDate = (date: string) => {
  const currentDate = new Date(date);
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
  var year = currentDate.getFullYear();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();

  // Функция для добавления ведущего нуля к числам < 10
  const addLeadingZero = (number: number) => {
    return number < 10 ? "0" + number : number;
  };

  // Форматируем компоненты даты и времени
  const formattedDate =
    addLeadingZero(day) + "." + addLeadingZero(month) + "." + year;
  const formattedTime = addLeadingZero(hours) + ":" + addLeadingZero(minutes);

  // Объединяем дату и время
  return formattedDate + " " + formattedTime;
};
