import { Box, Grid, InputLabel, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { FC, useState } from "react";
import { UpdateChannelFolderModal } from "../../organisms/UpdateChannelFolderModal";
import { Channel } from "../../../types";

interface FolderFieldProps {
  label: string;
  channel: Channel;
  refetch: () => void;
}

export const FolderField: FC<FolderFieldProps> = ({
  label,
  channel,
  refetch,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <Grid item={true} xs={1}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InputLabel>{label}</InputLabel>
          <EditIcon
            fontSize="small"
            style={{ transition: "all 0.2s linear" }}
            sx={{
              ml: "6px",
              cursor: "pointer",
              "&:hover": { color: "#1976d2" },
            }}
            color="disabled"
            onClick={() => setModalOpened(true)}
          />
        </Box>

        <Typography
          sx={{
            padding: "8px 12px",
            paddingLeft: 0,
            width: "100%",
            whiteSpace: "pre-line",
          }}
        >
          {channel?.folder?.name || "-"}
        </Typography>
      </Grid>
      <UpdateChannelFolderModal
        open={modalOpened}
        handleClose={() => setModalOpened(false)}
        channel={channel}
        refetch={refetch}
      />
    </>
  );
};
