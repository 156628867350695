import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Order } from "../../../types";
import EditIcon from "@mui/icons-material/Edit";
import { UpdateOrderModal } from "../../organisms/UpdateOrderModal";

interface OrderItemProps {
  order: Order;
  refetch: () => void;
}

export const OrderItem: FC<OrderItemProps> = ({ order, refetch }) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModal(false);
  };
  return (
    <>
      <Box
        sx={{
          height: "40px",
          borderBottom: "1px solid #e0e0e0",
          display: "grid",
          gridTemplateColumns: "0.2fr 0.5fr 1.2fr 0.8fr 0.5fr 0.5fr 0.2fr",
          px: "8px",
          paddingBottom: "10px",
          "&:hover": {
            background: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          ID:
          {order.id}
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
          }}
        >
          External_ID:
          {order.external_id}
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Название услуги: "}
          <Typography sx={{ ml: "4px" }}>{order.name}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            justifyContent: "end",
          }}
        >
          {"Тип услуги: "}{" "}
          <Typography sx={{ ml: "4px" }}>{order.type}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          {"Min "}{" "}
          <Typography sx={{ ml: "4px" }}>{order.min_value || "-"}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          {"Max: "}{" "}
          <Typography sx={{ ml: "4px" }}>{order.max_value || "-"}</Typography>
        </Box>
        <Box
          sx={{
            color: "#616161",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setModal(true)}
        >
          <EditIcon
            sx={{ mr: "12px" }}
            onClick={() => {
              setModal(true);
            }}
          />
        </Box>
      </Box>
      <UpdateOrderModal
        open={modal}
        handleClose={handleCloseModal}
        refetch={refetch}
        order={order}
      />
    </>
  );
};
