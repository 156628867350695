import { Box, Button, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
// import { Link as RouterLink } from "react-router-dom";
import { LoginForm } from "../types";
import { joiResolver } from "@hookform/resolvers/joi";
import { getLoginSchema } from "../../utils/schemas/AuthSchemas";
import { useContext } from "react";
import { authStore } from "../store/AuthStore";
import { useMutation } from "react-query";
import { AuthAPI } from "../../utils/api/requests/auth-requests";
import { toast } from "react-toastify";
import { AxiosError } from "../../utils/api/axiosErrorType";

export const Login = () => {
  const { setSessionId } = useContext(authStore);
  const form = useForm<LoginForm>({
    mode: "onChange",
    resolver: joiResolver(getLoginSchema()),
  });
  const { handleSubmit, control, formState } = form;
  const { isDirty, isValid } = formState;

  const { mutate, isLoading } = useMutation({
    mutationFn: AuthAPI.getSessionId,

    onSuccess: ({ access_token }) => {
      setSessionId(access_token);
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const onSubmit = async (formData: LoginForm) => {
    mutate(formData);
  };

  return (
    <>
      <Typography variant="h4">{"Вход"}</Typography>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        width={360}
        marginTop="40px"
      >
        <Controller
          control={control}
          name="username"
          defaultValue=""
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              label={"Имя пользователя"}
              variant="outlined"
              autoComplete="off"
              autoFocus={true}
              sx={{
                marginBottom: "40px",
              }}
              error={!!error}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              type="password"
              inputRef={ref}
              label={"Пароль"}
              variant="outlined"
              autoComplete="off"
              sx={{
                marginBottom: "40px",
              }}
              error={!!error}
            />
          )}
        />

        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!isDirty || !isValid || isLoading}
        >
          {"Войти"}
        </Button>
      </Box>
      {/* <Box display="flex" marginTop="32px" justifyContent="center" width={320}>
        <Typography variant="inherit" marginRight={"4px"}>
          Нет аккаунта?{" "}
        </Typography>
        <Link
          component={RouterLink}
          to={{ pathname: "/signin" }}
          underline="hover"
        >
          {"Зарегистрироваться"}
        </Link>
        <Link
          component={RouterLink}
          to={{ pathname: "/forgot" }}
          underline="hover"
        >
          {"Забыли пароль?"}
        </Link>
      </Box> */}
    </>
  );
};
