import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { OverlayLoader } from "./features/ui/atoms/OveralyLoader";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async () => {},
      // throwErrorToast(
      //     await translator.then((t) => t("common.smthWrong"))
      // ),
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: false,
    },
    mutations: {
      onError: async () => {},
      // throwErrorToast(
      //     await translator.then((t) => t("common.smthWrong"))
      // )
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Suspense fallback={<OverlayLoader />}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Suspense>
  </BrowserRouter>
);
