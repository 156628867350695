import { Box, Button, Typography } from "@mui/material";
import { FC, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface FileUploadProps {
  name: string;
  label: string;
  text: string;
  accept: string;
}

export const FileUpload: FC<FileUploadProps> = ({
  name,
  text,
  label,
  accept,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const context = useFormContext();
  if (!context) {
    throw new Error("Provide FormContext before FileUpload");
  }
  const { control } = context;
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onChange(file);
  };

  const onRemove = () => {
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    onChange(undefined);
  };

  return (
    <Box sx={{ mt: "12px" }}>
      {!value ? (
        <Button
          component="label"
          htmlFor={name}
          variant="outlined"
          fullWidth
          size="large"
        >
          {label}
        </Button>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: "9px",
          }}
        >
          <Typography
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {text} {value?.name}
          </Typography>
          <DeleteOutlineIcon
            fontSize="small"
            color="action"
            sx={{ ml: "4px", cursor: "pointer" }}
            onClick={onRemove}
          />
        </Box>
      )}
      <input
        onChange={(e) => onInputChange(e)}
        name={name}
        id={name}
        type="file"
        hidden={true}
        accept={accept}
        ref={inputRef}
      />
    </Box>
  );
};
