import { Account } from "../../../types";
import { get, patch, post, remove } from "../axiosConfig";

export const AccountAPI = {
  async getAccountsList(): Promise<Account[]> {
    const { data } = await get("/api/v1/accounts");
    return data;
  },
  async addAccount({
    proxy,
    file_session,
    file_meta,
  }: {
    proxy: string;
    file_session: File;
    file_meta: File;
  }): Promise<true> {
    const formData = new FormData();
    formData.append("proxy", proxy);
    formData.append("file_session", file_session);
    formData.append("file_meta", file_meta);
    const { data } = await post("/api/v1/accounts", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
  async deleteAccount(id: string): Promise<true> {
    const { data } = await remove(`/api/v1/accounts/${id}`);
    return data;
  },
  async updateProxy({
    id,
    proxy,
  }: {
    id: string;
    proxy: string;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/accounts/${id}/proxy`, proxy);
    return data;
  },
};
