import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { FC } from "react";

interface QueryDatePickerProps {
  value?: Date;
  setValue: (date: Date) => void;
  placeholder: string;
}

export const QueryDatePicker: FC<QueryDatePickerProps> = ({
  value,
  setValue,
  placeholder,
}) => {
  return (
    <Box className="select-date-picker">
      <DatePicker
        locale={ru}
        showTimeSelect
        selected={value}
        onChange={setValue}
        dateFormat="dd.MM.yy HH:mm"
        timeCaption={"Время"}
        placeholderText={placeholder}
      />
    </Box>
  );
};
