import { Order, OrderPayload } from "../../../types";
import { get, patch, post } from "../axiosConfig";

export const OrderAPI = {
  async getOrdersList(): Promise<Order[]> {
    const { data } = await get("/api/v1/orders");
    return data;
  },
  async addOrder(formdata: OrderPayload): Promise<true> {
    const { data } = await post("/api/v1/orders", formdata);
    return data;
  },
  async updateOrder({
    id,
    formdata,
  }: {
    id: number;
    formdata: Order;
  }): Promise<true> {
    if (!formdata.min_value) {
      formdata = { ...formdata, min_value: null };
    }
    if (!formdata.max_value) {
      formdata = { ...formdata, max_value: null };
    }
    const { data } = await patch(`/api/v1/orders/${id}`, formdata);
    return data;
  },
};
