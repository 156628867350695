import { Box, Grid, InputLabel } from "@mui/material";
import React, { FC } from "react";

interface FieldProps {
  label: string;
  value?: string;
}

export const Field: FC<FieldProps> = ({ label, value }) => {
  return (
    <Grid item={true} xs={1}>
      <InputLabel>{label}</InputLabel>
      <Box
        sx={{
          padding: "8px 12px",
          paddingLeft: 0,
          width: "100%",
        }}
      >
        {value}
      </Box>
    </Grid>
  );
};
