import Joi from "joi";
import {
  ChannelPayload,
  FolderPayload,
  UpdateChannelFolderPayload,
} from "../../types";
export const getChannelSchema = () =>
  Joi.object<ChannelPayload>({
    channel_link: Joi.string().required(),
    folder_id: Joi.number(),
  });

export const getFolderSchema = () =>
  Joi.object<FolderPayload>({
    name: Joi.string().required(),
  });

export const getUpdateChannelFolderSchema = () =>
  Joi.object<UpdateChannelFolderPayload>({
    folder_id: Joi.number(),
  });
