import { Box, Typography } from "@mui/material";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import { getFormattedNumber } from "../../../utils/GetFormattedNumber";
import { Chart } from "../../../types";
import { FC } from "react";

interface ChartProps {
  Chart: Chart[];
}

export const ChartComponent: FC<ChartProps> = ({ Chart }) => {
  const CustomTooltip = (props: TooltipProps<number, string>) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "#FFF",
            padding: "4px 4px",
            borderRadius: "4px",
            width: "180px",
          }}
          boxShadow={1}
        >
          <Typography>{new Date(Chart[label].date).toDateString()}</Typography>
          <Typography>
            Подписчики: {getFormattedNumber(Chart[label].value)}
          </Typography>
        </Box>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={Chart}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="chart_value"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
