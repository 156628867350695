import { Box, CircularProgress, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery } from "react-query";
import { AccountAPI } from "../utils/api/requests/account-requests";
import { AccountItem } from "../ui/molecules/AccountItem";
import { AddPostModal } from "../ui/organisms/AddAccountModal";
import { useState } from "react";

export const Accounts = () => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const { data, refetch } = useQuery({
    queryFn: AccountAPI.getAccountsList,
    queryKey: "accounts",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Box sx={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <Typography variant="h4">Аккаунты</Typography>
        <AddCircleIcon
          fontSize="medium"
          style={{ transition: "all 0.2s linear" }}
          sx={{
            ml: "6px",
            mt: "4px",
            cursor: "pointer",
            "&:hover": { color: "#1976d2" },
          }}
          color="disabled"
          onClick={() => {
            setModalOpened(true);
          }}
        />
        {/* Обновить */}
      </Box>
      {!data && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {data &&
        data.length !== 0 &&
        data.map((account) => {
          return <AccountItem account={account} key={account.id} />;
        })}
      {data && data.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Аккаунтов нет</div>
        </Box>
      )}
      <AddPostModal
        open={modalOpened}
        handleClose={() => {
          setModalOpened(false);
        }}
        refetch={refetch}
      />
    </>
  );
};
