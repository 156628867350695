import { LoginResponse, SigninResponse } from "../../../auth/types";
import { get, post } from "../axiosConfig";
import { User } from "../../../types";

export const AuthAPI = {
  async signinUser(formData: {
    username: string;
    email: string;
    password: string;
  }): Promise<SigninResponse> {
    const { data } = await post("/api/v1/signup", formData);
    return data.result;
  },
  async getSessionId(formData: {
    username: string;
    password: string;
  }): Promise<LoginResponse> {
    const { data } = await post("/api/v1/token", formData);
    return data;
  },
  async getUserBySessionId(): Promise<User> {
    const { data } = await get("/api/v1/me");
    return data;
  },
};
