import Joi from "joi";
import { ForgotForm, LoginForm, SigninForm } from "../../auth/types";

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const getLoginSchema = () =>
  Joi.object<LoginForm>({
    username: Joi.string().required(),
    password: Joi.string().required(),
  });

export const getSigninSchema = () =>
  Joi.object<SigninForm>({
    username: Joi.string().required(),
    email: Joi.string().trim().pattern(new RegExp(emailRegExp)).required(),
    password: Joi.string().required(),
    repeatPassword: Joi.any().valid(Joi.ref("password")).required(),
  });

export const getForgotSchema = () =>
  Joi.object<ForgotForm>({
    email: Joi.string().trim().pattern(new RegExp(emailRegExp)).required(),
  });
