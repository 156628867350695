import { Box, InputLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { useController } from "react-hook-form";
import { FC } from "react";

interface DatePickerComponentProps {
  name: string;
  isEditing: boolean;
}

export const DatePickerComponent: FC<DatePickerComponentProps> = ({
  name,
  isEditing,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
  });

  const getFormattedDate = (date: Date) => {
    const year = date.getFullYear().toString().slice(-2); // Последние две цифры года
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяц с ведущим нулем, так как индексы месяцев начинаются с 0
    const day = date.getDate().toString().padStart(2, "0"); // День с ведущим нулем
    const hours = date.getHours().toString().padStart(2, "0"); // Часы с ведущим нулем
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Минуты с ведущим нулем

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <Box sx={{ ml: "20px" }}>
      <InputLabel>Время</InputLabel>
      {isEditing ? (
        <DatePicker
          locale={ru}
          showTimeSelect
          selected={value}
          onChange={onChange}
          dateFormat="dd.MM.yy HH:mm"
          timeCaption={"Время"}
          minDate={new Date()}
        />
      ) : (
        <Box
          sx={{
            width: "132px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {getFormattedDate(value)}
        </Box>
      )}
    </Box>
  );
};
