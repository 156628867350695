import { NumberFormatProps, NumberFormatValues } from "react-number-format";

export const needMultiply = (
  multiplyingKoef: number,
  onChange: (value: number | string) => void
) => {
  return ({ floatValue }: NumberFormatValues) =>
    onChange(
      floatValue !== undefined
        ? Math.round(Number(floatValue) * multiplyingKoef)
        : ""
    );
};

export const getValue = (
  value: NumberFormatProps["value"],
  isNumber: boolean,
  multiplyingKoef: number
) => {
  if (value === undefined || value === null) {
    return "";
  }

  if (isNumber && value !== "") {
    return Number(value) / multiplyingKoef;
  }

  return value;
};
