import {
  Box,
  CircularProgress,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { AddChannelModal } from "../ui/organisms/AddChannelModal";
import { ChannelAPI } from "../utils/api/requests/channel-requests";
import { ChannelItem } from "../ui/molecules/ChannelItem";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { AddFolderModal } from "../ui/organisms/AddFolderModal";
import { FolderItem } from "../ui/molecules/FolderItem";
import { observer } from "mobx-react-lite";
import { authStore } from "../auth/store/AuthStore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { queryClient } from "../..";

export const Channels = observer(() => {
  const { folder, setFolder } = useContext(authStore);
  const [sort_direction, setSortDirection] = useState<"asc" | "desc">("asc");
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [addFolderModal, setAddFolderModal] = useState<boolean>(false);

  const { data, refetch } = useQuery(
    ["channels", folder, sort_direction],
    () => ChannelAPI.getChannelsList({ folder_id: folder?.id, sort_direction }),
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (folder) {
      queryClient.resetQueries("channels");
      refetch();
    }
  }, [folder, refetch, sort_direction]);

  const { data: foldersData, refetch: refetchFolders } = useQuery({
    queryFn: ChannelAPI.getFoldersList,
    queryKey: "folders",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Box
        sx={{
          marginBottom: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ marginBottom: "12px", display: "flex", alignItems: "center" }}
        >
          {folder && (
            <ArrowBackIosIcon
              fontSize="medium"
              style={{ transition: "all 0.2s linear" }}
              sx={{
                ml: "6px",
                mt: "4px",
                cursor: "pointer",
                "&:hover": { color: "#1976d2" },
              }}
              color="disabled"
              onClick={() => {
                setFolder(null);
              }}
            />
          )}
          <Typography variant="h4">
            Каналы{folder && "/" + folder.name}
          </Typography>
          <AddCircleIcon
            fontSize="medium"
            style={{ transition: "all 0.2s linear" }}
            sx={{
              ml: "6px",
              mt: "4px",
              cursor: "pointer",
              "&:hover": { color: "#1976d2" },
            }}
            color="disabled"
            onClick={() => {
              setModalOpened(true);
            }}
          />
          {!folder && (
            <CreateNewFolderIcon
              fontSize="medium"
              style={{ transition: "all 0.2s linear" }}
              sx={{
                ml: "6px",
                mt: "4px",
                cursor: "pointer",
                "&:hover": { color: "#1976d2" },
              }}
              color="disabled"
              onClick={() => {
                setAddFolderModal(true);
              }}
            />
          )}
        </Box>
        {folder && (
          <Select
            value={sort_direction}
            onChange={(e) => {
              setSortDirection(e.target.value as "desc" | "asc");
            }}
            sx={{ width: "160px" }}
            size="small"
          >
            <ListSubheader>Подписчики:</ListSubheader>
            <MenuItem value={"asc"}>По возрастанию</MenuItem>
            <MenuItem value={"desc"}>По убыванию</MenuItem>
          </Select>
        )}
        {/* Обновить */}
      </Box>
      {!foldersData && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!folder &&
        foldersData &&
        foldersData.length !== 0 &&
        foldersData.map((folder) => {
          return (
            <FolderItem
              folder={folder}
              key={folder.id}
              refetch={refetchFolders}
              setFolder={setFolder}
            />
          );
        })}
      {folder &&
        data &&
        data.length !== 0 &&
        data.map((channel) => {
          return (
            <ChannelItem channel={channel} key={channel.id} refetch={refetch} />
          );
        })}
      {folder && data && data.length === 0 && (
        <Box
          height="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 1,
            my: "200px",
          }}
        >
          <div>Каналов нет</div>
        </Box>
      )}
      <AddChannelModal
        open={modalOpened}
        handleClose={() => {
          setModalOpened(false);
        }}
        refetch={refetch}
      />
      <AddFolderModal
        open={addFolderModal}
        handleClose={() => setAddFolderModal(false)}
        refetch={refetchFolders}
      />
    </>
  );
});
