import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { Channel, UpdateChannelFolderPayload } from "../../../types";
import { ChannelAPI } from "../../../utils/api/requests/channel-requests";
import { getUpdateChannelFolderSchema } from "../../../utils/schemas/ChannelSchemas";
import { Select } from "../../atoms/Select";

interface UpdateChannelFolderModalProps {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  channel: Channel;
}

export const UpdateChannelFolderModal: FC<UpdateChannelFolderModalProps> = ({
  open,
  handleClose,
  refetch,
  channel,
}) => {
  const { data } = useQuery({
    queryFn: ChannelAPI.getFoldersList,
    queryKey: "folders",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const [folderData, setFolderData] = useState<
    null | { value: number; label: string }[]
  >(null);

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const inputData = data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setFolderData(inputData);
    }
  }, [data]);

  const form = useForm<UpdateChannelFolderPayload>({
    mode: "onChange",
    resolver: joiResolver(getUpdateChannelFolderSchema()),
  });
  const { handleSubmit, formState, reset } = form;

  const { isDirty, isValid } = formState;

  const { mutate, isLoading } = useMutation({
    mutationFn: ChannelAPI.updateChannelFolder,
    onSuccess: () => {
      refetch();
      toast.success("Папка канала успешно изменена");
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const onSubmit = async (formData: UpdateChannelFolderPayload) => {
    mutate({
      folder_id: formData.folder_id,
      id: channel.id,
    });
  };

  useEffect(() => {
    if (!open) {
      reset();
    } else {
      reset({ folder_id: channel.folder.id });
    }
  }, [channel.folder.id, open, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Переместить в папку</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", width: "350px" }}
          >
            {folderData && (
              <Select
                name={"folder_id"}
                isEditing={true}
                data={folderData}
                fullWidth
                hideLabel
              />
            )}
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
