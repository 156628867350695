import { useContext } from "react";
import { authStore } from "./features/auth/store/AuthStore";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./features/auth/pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OverlayLoader } from "./features/ui/atoms/OveralyLoader";
import { MainTemplate } from "./features/utils/templates/MainTemplate";

export const App = observer(() => {
  const { sessionId, user } = useContext(authStore);

  if (sessionId === null) {
    return (
      <>
        <Box
          display="flex"
          height="100vh"
          component="main"
          flex={1}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Box>
        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
          style={{ padding: "10px" }}
        />
      </>
    );
  }
  if (!user) {
    return <OverlayLoader />;
  }
  return <MainTemplate />;
});
