import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { UpdateProxyPayload } from "../../../types";
import { AccountAPI } from "../../../utils/api/requests/account-requests";
import { getUpdateProxySchema } from "../../../utils/schemas/AccountSchemas";

interface UpdateProxyModalProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  proxy: string;
}

export const UpdateProxyModal: FC<UpdateProxyModalProps> = ({
  open,
  handleClose,
  id,
  proxy,
}) => {
  const form = useForm<UpdateProxyPayload>({
    mode: "onChange",
    resolver: joiResolver(getUpdateProxySchema()),
  });
  const { handleSubmit, formState, control, reset } = form;

  const { isDirty, isValid } = formState;

  const { refetch } = useQuery({
    queryFn: AccountAPI.getAccountsList,
    queryKey: "accounts",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: AccountAPI.updateProxy,
    onSuccess: () => {
      refetch();
      toast.success("Прокси успешно изменен");
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      const errorText = err?.response?.data?.detail;
      if (typeof errorText === "string") {
        toast.error(err?.response?.data?.detail);
      } else {
        toast.error("Ошибка");
      }
    },
  });

  const onSubmit = async (formData: UpdateProxyPayload) => {
    const { proxy } = formData;
    mutate({ proxy, id: id });
  };

  useEffect(() => {
    reset({ proxy: proxy });
  }, [proxy, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Изменить прокси</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", width: "350px" }}
          >
            <Controller
              control={control}
              name="proxy"
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={"Прокси"}
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    marginTop: "12px",
                  }}
                  error={!!error}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
