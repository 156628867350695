import Joi from "joi";
import { AccountPayload, UpdateProxyPayload } from "../../types";
export const getAccountSchema = () =>
  Joi.object<AccountPayload>({
    proxy: Joi.string().required(),
    file_session: Joi.object().required().unknown(true),
    file_meta: Joi.object().required().unknown(true),
  });

export const getUpdateProxySchema = () =>
  Joi.object<UpdateProxyPayload>({
    proxy: Joi.string().required(),
  });
