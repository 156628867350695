import { green } from "@mui/material/colors";

export const GetSubscribersColor = (count: number) => {
  if (count > 0) {
    return green[500];
  }
  if (count < 0) {
    return "error";
  }
};
