import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { AccountAPI } from "../../../utils/api/requests/account-requests";

interface DeleteAccountModalProps {
  open: boolean;
  handleClose: () => void;
  id: string;
}

export const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
  open,
  handleClose,
  id,
}) => {
  const { refetch } = useQuery({
    queryFn: AccountAPI.getAccountsList,
    queryKey: "accounts",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: AccountAPI.deleteAccount,
    onSuccess: () => {
      toast.success("Аккаунт успешно удалён");
      refetch();
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const handlePostDelete = async () => {
    mutate(id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Удаление аккаунта</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ minWidth: "350px", minHeight: "20px" }}
        >
          Вы действительно хотите удалить аккаунт?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "10px" }}>
        <Button variant="outlined" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          onClick={handlePostDelete}
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
